import { Ability, ForcedSubject } from "@casl/ability";
import { WorkOrder } from "@fyvedev/client";

export enum AbilityActions {
  Create = "create",
  Read = "read",
  Write = "write",
  Delete = "delete",
  Pay = "pay",
}
type Subjects = "Ticket";

export type TAppAbility = [AbilityActions, WorkOrder & ForcedSubject<Subjects>];

const ability = new Ability<TAppAbility>();
export default ability;
