import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";
import sentryBaseConfig from "sentry.base.config";

const SENTRY_DSN = process
  ? process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
  : "";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ...sentryBaseConfig,
    integrations: [new BrowserTracing()],
  });
}
