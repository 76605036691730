import { Loader } from "@fyvedev/fe-storybook";
import useLoader from "lib/hooks/useLoader";
import React from "react";

export const ChangePathLoader: React.FC = () => {
  const loading = useLoader();

  if (!loading) {
    return null;
  }

  return (
    <div className="fixed w-full h-full max-h-screen bg-white/80 flex items-center justify-center z-50 top-0">
      <Loader size={6.25} type="page" className="h-auto w-auto p-1" />
    </div>
  );
};
