/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/ban-types */
import "../styles/font/inter.css";
import "../styles/globals.css";

import { ClientProvider } from "@fyvedev/client";
import ability from "casl/ability";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import NProgress from "nprogress";
import React from "react";
import { NextPageWithLayout } from "types/next-page-with-layout";
import { AbilityContext } from "../components/ability/Can";
import { ChangePathLoader } from "@/components/templates/app/ChangePathLoader";
import { Portal } from "@fyvedev/fe-storybook";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("@fyvedev/client/dist/mocks");
}

type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout;
};

NProgress.configure({ showSpinner: false });

const MyApp = ({
  Component,
  pageProps,
}: AppPropsWithLayout<{ session: Session; dehydratedProps: any }>) => {
  const getLayout = (Component.getLayout ?? ((page: any) => page)) as Function;

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ClientProvider dehydratedProps={pageProps.dehydratedProps}>
        <SessionProvider session={pageProps.session}>
          <AbilityContext.Provider value={ability}>
            {getLayout(<Component {...pageProps} />)}
            <Portal isShow id="loader">
              <ChangePathLoader />
            </Portal>
          </AbilityContext.Provider>
        </SessionProvider>
      </ClientProvider>
    </>
  );
};

export default MyApp;
