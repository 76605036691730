import { useRouter } from "next/router";
import React from "react";

export default function useLoader() {
  const router = useRouter();

  const [loading, setLoading] = React.useState(false);
  const [currentUrl, setCurrentUrl] = React.useState("");

  React.useEffect(() => {
    if (currentUrl === router.asPath) {
      setLoading(false);
    }
  }, [currentUrl, router]);

  React.useEffect(() => {
    const handleStart = (url: string) => {
      setCurrentUrl(url);
      if (url !== router.asPath) {
        setLoading(true);
      }
    };

    const handleComplete = (url: string) => {
      setCurrentUrl(url);
      if (url === router.asPath) {
        setLoading(false);
      }
    };

    router.events.off("routeChangeStart", handleStart);
    router.events.off("routeChangeComplete", handleComplete);
    router.events.off("routeChangeError", handleComplete);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  return loading;
}
